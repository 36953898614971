import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./PostList.scss";
import RenderContent from "../../RenderContent";
import { Pagination } from "../../Pagination";
import { Image } from "../../Image";
import { PostListItem } from "../PostListItem";
import { PostListNavigation } from "../PostListNavigation";

export const PostList = ({ posts, pageContext, categories }) => {
  return (
    <section className="post-list">
      <div className="wrap">
        <PostListNavigation categories={categories.edges} />
        <div className="project-list-grid">
          {Object.keys(posts).length > 0 &&
            posts?.map((post, index) => {
              return (
                !post.node.slug.includes("gatsby") && (
                  <PostListItem
                    key={index}
                    data={post.node}
                    categories={categories}
                    showMedia
                  />
                )
              );
            })}
        </div>
        <Pagination pageContext={pageContext} pathPrefix="/news/" />
      </div>
    </section>
  );
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
      path
    }
    title
    excerpt
    date
    slug
    path
    wordpress_id
    acf {
      layout: layout_post {
        ...PostInformationQuery
      }
    }
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
